


/*===============================
    37.TEAM MEMBER css 
================================*/


.team-member-area{
    margin-top: 2rem;
    padding-bottom: 120px;
    overflow: hidden;
    & .section-title{
        & .title{
            font-size: 55px;
            margin-bottom: 48px;
            @media #{$lg} {
                font-size: 44px;
                line-height: 54px;
            }
            @media #{$md} {
                font-size: 46px;
                line-height: 56px;
                padding: 0 40px;

            }
            @media #{$xs} {
                font-size: 30px;
                line-height: 40px;

            }
            @media #{$sm} {
                font-size: 36px;
                line-height: 46px;

            }
            &::before{
                display: none;
            }
        }
    }
    & .single-team-member{
        position: relative;
        & img{
            width: 100%;
        }
        & .team-member-overlay{
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            border-radius: 6px;
            background: $theme-color;
            text-align: center;
            padding: 25px 0px;
            opacity: 0;
            @include transition(0.3s);
            @media #{$laptop} {
                padding: 15px 0;
            }
            @media #{$lg} {
                padding: 15px 0;
            }
            & ul{
                & li{
                    display: inline-block;
                    & a{
                        font-size: 15px;
                        color: #87b4ff;
                        margin: 0 7px;
                        @include transition(0.3s);
                        @media #{$laptop} {
                            margin: 0 4px;
                        }
                        @media #{$lg} {
                            margin: 0 3px;
                            font-size: 13px;
                        }
                        &:hover{
                            color: $white;
                        }
                    }
                }
            }
            & .title{
                color: $white;
                font-size: 24px;
                text-transform: capitalize;
                padding-top: 7px;
                @media #{$laptop} {
                    font-size: 18px;
                }
                @media #{$lg} {
                    font-size: 14px;
                }
            }
            & span{
                font-size: 14px;
                color: #9cbef7;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 3px;
                @media #{$laptop} {
                    font-size: 12px;
                }
                @media #{$lg} {
                    font-size: 10px;
                }
            }
        }
        &:hover{
            & .team-member-overlay{
                left: 20px;
                bottom: -20px;
                opacity: 1;
            }
        }
    }
    & .container-fluid{
        @media #{$md} {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media #{$xs} {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}