@import "~bootstrap/scss/bootstrap";

.single-product{
  @extend .col-lg-4;
  @extend .col-md-4;
  @extend .col-sm-6;
}
$fa-font-path:        "../webfonts";




@import "./pages/product/index";

@import "./assets/css/all.css";
@import "./assets/scss/style.scss";


@import "./assets/css/nice-select.css";

@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import '~react-modal-video/scss/modal-video.scss';

@import "./assets/css/animate.min.css";

.float{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
  margin-top:16px;
}