

ol {
    list-style-type: decimal;
    list-style-position: inside;
    box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
    .containerImagePage {
        display: flex;
        flex-direction: column;
    }
}

.containerWawa {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
}

p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #81a3bb;
    margin: 0px;
}

.imagesCompany {
    width: 80%;
    @media only screen and (max-width: 767px) {
            width: 100%;
            height: 58%;
        }
    height: auto;
}

.imageContener {
    flex: 0 0 auto;
}


p{
    overflow: hidden;
}

p:last-child {
    overflow: visible;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.item {
    @media screen and (max-device-width: 1065px) {
        width: 100%;
    }

    box-sizing: border-box;
    width: 50%;
    margin-bottom: 20px;
    padding: 0px 10px;
}

.content {
    color: #242424;
    font-weight: 600;
    text-align: center;
    box-sizing: border-box;
    height: 100%;
    padding: 10px;
}

.card {
    width: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: auto;
    margin-left: auto;
}

.card-content {
    padding: 16px;
}

.card-title {
    font-size: 18px;
    margin-bottom: 8px;
}

.card-description {
    font-size: 14px;
    color: #777;
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.card-content {
    padding: 16px;
}

.card-title {
    font-size: 18px;
    margin-bottom: 8px;
}

.card-description {
    font-size: 14px;
    color: #777;
}

.containerCard {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
}