// Font Family
$karla: 'Karla', sans-serif;
$gilroy: 'GilroyBold';


// Colors ---------------

$white: #fff;

$black: #232323;

$grey:#f7f7fd;

$theme-color: #2E6ADC;

$text-color: #81a3bb;
$text-color-2: #6c90b9;
$text-color-3: #69a1bb;


$border-color:#e3eeff;
$border-color-2:#ededed;


// Heading Color
$heading-color: #002249;

// Soft color
$black-soft:#4f79a9;


// Responsive Variables
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1600px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$xs: '(max-width: 575px)';


